body {
  margin: 0;
  font-family: "Raleway,sans-serif", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.makeStyles-marginTop-184 {
  margin-top: 2em !important;
}
.makeStyles-marginTop-37 {
  margin-top: 2em !important;
}
.makeStyles-marginTop-15 {
  margin-top: 2em !important;
}
.makeStyles-back-36 .css-480o17-MuiGrid-root > .MuiGrid-item {
  padding: 0% !important;
}
.css-r40f8v-MuiTypography-root {
  letter-spacing: normal !important;
}
.makeStyles-space-52 {
  padding-bottom: 0px !important;
}
.css-1kgfegd-MuiTypography-root-MuiLink-root {
  text-decoration: none !important;
}
.css-aygekn {
  padding: 0px !important;
}
a:-webkit-any-link {
  /* color: white !important; */
  text-decoration: none !important;
  font-weight: 500 !important;
  /* font-weight: 500 !important; */
}
.css-1kgfegd-MuiTypography-root-MuiLink-root {
  margin-top: 20px !important;
}
.css-1fh410u-MuiGrid-root > .MuiGrid-item {
  padding-top: 2em !important;
  margin-top: 1em !important;
}
.css-480o17-MuiGrid-root > .MuiGrid-item {
  padding-top: 3 !important;
}
video {
  border-radius: 20px !important;
}
.App__form {
  margin: 0 !important;
}
ul {
  padding: 0% !important;
}
.App__form {
  max-width: auto !important;
  padding: 30px 37px !important;
  margin: 27px auto !important;
}

.gradiant {
  background-image: linear-gradient(
    to bottom right,
    #00c4f5,
    #4adb29
  ) !important;
}
.css-qalymo {
  padding: 0% !important;
}
.css-honmcm-MuiGrid-root > .MuiGrid-item {
  padding-top: 2em !important;
}
a:-webkit-any-link {
  margin-bottom: 8px !important;
}
.makeStyles-date-197 {
  position: absolute !important;
  margin-top: -3em !important;
}
